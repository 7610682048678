import * as React from 'react';
export function StyleEditor() {
    return (React.createElement("style", null, "\n  .rte-editor-iframeContainer {\n    width: 100%;\n    height: 0;\n    position: relative;\n    min-height: 300px;\n    padding-bottom: 56.25%;\n  }\n\n  .rte-editor-iframe {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n\n  .rte-editor-invalidVideoSrc {\n    text-align: center;\n    background-color: #eaeaea;\n    padding: 1em;\n  }\n\n  .rte-editor-video {\n    width: 100%;\n    height: 100%;\n  }\n        "));
}
export var defaultThemeVideo = {
    iframeContainer: 'rte-editor-iframeContainer',
    iframe: 'rte-editor-iframe',
    invalidVideoSrc: 'rte-editor-invalidVideoSrc',
    video: 'rte-editor-video',
};
export var editorStyle = {
    minHeight: '6em',
    padding: '.625rem .75rem',
    width: '100%',
};
