var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { PaymentRequestButtonElement, useStripe, } from '@stripe/react-stripe-js';
function useOptions(paymentRequest) {
    var options = React.useMemo(function () { return ({
        paymentRequest: paymentRequest,
        style: {
            paymentRequestButton: {
                theme: 'dark',
                height: '48px',
                type: 'buy'
            }
        }
    }); }, [paymentRequest]);
    return options;
}
function usePaymentRequest(_a) {
    var options = _a.options, onPaymentMethod = _a.onPaymentMethod;
    var stripe = useStripe();
    var _b = __read(React.useState(null), 2), paymentRequest = _b[0], setPaymentRequest = _b[1];
    var _c = __read(React.useState(false), 2), canMakePayment = _c[0], setCanMakePayment = _c[1];
    React.useEffect(function () {
        if (stripe && paymentRequest === null) {
            var pr = stripe.paymentRequest(options);
            setPaymentRequest(pr);
        }
    }, [stripe, options, paymentRequest]);
    React.useEffect(function () {
        var subscribed = true;
        if (paymentRequest) {
            paymentRequest.canMakePayment().then(function (res) {
                if (res && subscribed) {
                    setCanMakePayment(true);
                }
            });
        }
        return function () {
            subscribed = false;
        };
    }, [paymentRequest]);
    React.useEffect(function () {
        if (paymentRequest) {
            paymentRequest.on('paymentmethod', onPaymentMethod);
        }
        return function () {
            if (paymentRequest) {
                paymentRequest.off('paymentmethod', onPaymentMethod);
            }
        };
    }, [paymentRequest, onPaymentMethod]);
    return canMakePayment ? paymentRequest : null;
}
function StripePaymentButtons(_a) {
    var doPaymentRequest = _a.doPaymentRequest, invoice = _a.invoice;
    var paymentRequest = usePaymentRequest({
        options: {
            country: invoice.domain.company.country,
            currency: String(invoice.currency).toLowerCase(),
            total: {
                label: "Merchi Invoice #".concat(invoice.id),
                amount: Math.round(invoice.totalCost * 100)
            }
        },
        onPaymentMethod: doPaymentRequest
    });
    var options = useOptions(paymentRequest);
    if (!paymentRequest) {
        return null;
    }
    return (React.createElement("div", { style: { marginBottom: '2rem', marginTop: '2rem' } },
        React.createElement(PaymentRequestButtonElement, { className: 'PaymentRequestButton', options: options })));
}
export default StripePaymentButtons;
