var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { MerchiCartModal } from 'merchi_cart';
import { getNextBackendUri } from '../utilities';
var BACKEND_URI = getNextBackendUri();
export function MerchiCart(props) {
    return (React.createElement(MerchiCartModal, __assign({}, props, { initialiseCart: true, apiUrl: "".concat(BACKEND_URI, "v6/") })));
}
export function ShoppingCart(props) {
    var storeId = props.storeId;
    return (React.createElement(MerchiCart, __assign({}, props, { domainId: storeId })));
}
