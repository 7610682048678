import * as React from 'react';
import { urlFor } from '../../../public_components/url_map';
function CreditCardImg(_a) {
    var alt = _a.alt, fileName = _a.fileName, _b = _a.height, height = _b === void 0 ? '25' : _b;
    var filename = 'css/img/' + fileName;
    var src = urlFor('static', { filename: filename }, true);
    return (React.createElement("img", { style: { margin: '0 2px', height: "".concat(height, "px"), width: 'auto' }, src: src, alt: alt }));
}
function CreditCardImages(_a) {
    var className = _a.className, hideClass = _a.hideClass, _b = _a.height, height = _b === void 0 ? '25' : _b, _c = _a.showSquare, showSquare = _c === void 0 ? false : _c, _d = _a.showStripe, showStripe = _d === void 0 ? false : _d, _e = _a.showApplePay, showApplePay = _e === void 0 ? false : _e;
    return (React.createElement("div", { className: "".concat(hideClass, " card-icon-container ").concat(className), style: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
        } },
        showSquare &&
            React.createElement(CreditCardImg, { fileName: 'square-cc.png', alt: 'Square', height: height }),
        showStripe &&
            React.createElement(CreditCardImg, { fileName: 'stri-icon.png', alt: 'Stripe', height: height }),
        React.createElement(CreditCardImg, { fileName: 'mastercard-flat-64px.png', alt: 'master card', height: height }),
        React.createElement(CreditCardImg, { fileName: 'visa-flat-64px.png', alt: 'visa', height: height }),
        showApplePay &&
            React.createElement(CreditCardImg, { fileName: 'apple-pay-icon.png', alt: 'apple pay', height: height })));
}
export default CreditCardImages;
