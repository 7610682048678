var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
export function Row(_a) {
    var children = _a.children, className = _a.className, onClick = _a.onClick, style = _a.style;
    return (React.createElement("div", { className: "row ".concat(className), onClick: onClick, style: style }, children));
}
export function Col(_a) {
    var children = _a.children, className = _a.className, _b = _a.columns, columns = _b === void 0 ? '12' : _b, _c = _a.size, size = _c === void 0 ? 'lg' : _c;
    return (React.createElement("div", { className: "col-".concat(size, "-").concat(columns, " ").concat(className) }, children));
}
export var ListGroup = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return React.createElement("ul", __assign({ className: "list-group" }, rest), children);
};
export var ListGroupItem = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return React.createElement("li", __assign({ className: "list-group-item" }, rest), children);
};
export var FormGroup = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return React.createElement("div", __assign({ className: "form-group" }, rest), children);
};
