import * as React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import MerchiCheckout from 'merchi_checkout';
import { getNextBackendUri, getNextFrontendUri, getNextGoogleApi } from '../utilities';
function ModalMerchiCheckout(_a) {
    var currentUser = _a.currentUser, hideDrafting = _a.hideDrafting, includeDomainSignup = _a.includeDomainSignup, invoice = _a.invoice, isBuyNowRequest = _a.isBuyNowRequest, isOpen = _a.isOpen, redirectAfterSuccessUrl = _a.redirectAfterSuccessUrl, redirectAfterQuoteSuccessUrl = _a.redirectAfterQuoteSuccessUrl, redirectWithValue = _a.redirectWithValue, setInvoice = _a.setInvoice, setJob = _a.setJob, job = _a.job, product = _a.product, toggleMerchiCheckout = _a.toggleMerchiCheckout;
    return (React.createElement(Modal, { isOpen: isOpen, toggle: toggleMerchiCheckout, fullscreen: true, className: 'modal_merchi-checkout' },
        React.createElement(ModalBody, null,
            React.createElement(MerchiCheckout, { currentUser: currentUser, googlePlacesApiKey: getNextGoogleApi(), hideDrafting: hideDrafting, includeDomainSignup: includeDomainSignup, invoice: invoice, isBuyRequest: isBuyNowRequest, isOpen: isOpen, job: job, product: product, redirectAfterSuccessUrl: redirectAfterSuccessUrl, redirectAfterQuoteSuccessUrl: redirectAfterQuoteSuccessUrl, redirectWithValue: redirectWithValue, setInvoice: setInvoice, setJob: setJob, toggleMerchiCheckout: toggleMerchiCheckout, urlApi: "".concat(getNextBackendUri(), "v6/"), urlFrontend: getNextFrontendUri(), showDiscountCode: true }))));
}
export default ModalMerchiCheckout;
