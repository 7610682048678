var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as ReactDOM from "react-dom";
import { MERCHI_SDK } from './merchi-sdk';
import { debounce } from 'lodash';
import * as URL from './url_map';
import * as CURRENCY from './currency';
import { MerchiCart, ShoppingCart } from './MerchiCart';
import FormStripeCardWithStore from './stripe/forms/FormStripeCardWithStore';
import PublicProductWithMerchiCheckout from './PublicProductWithMerchiCheckout';
import { Groups, Variations } from './legacy_form';
import ProductDescription from './ProductDescription';
import { toReact as convertMerchiComponentTpReact } from './utilities';
import * as eHelpers from './merchi_entity_helpers';
var Cart = MerchiCart;
var deboPrice = debounce(function (job, success, error) {
    return MERCHI_SDK.getJobQuote(job, success, error);
}, 1000);
export var PRODUCT = {
    Groups: Groups,
    PublicProductWithMerchiCheckout: PublicProductWithMerchiCheckout,
    RichDescription: ProductDescription,
    Variations: Variations,
};
export var STRIPE = { FormStripeDynamicWithStore: FormStripeCardWithStore };
export var _debounce = debounce;
export var toReact = convertMerchiComponentTpReact;
export var debounceFetchPrice = deboPrice;
export { Cart, CURRENCY, ShoppingCart };
export var URL_MAP = URL;
export var entityHelpers = __assign({}, eHelpers);
export var reactLibrary = { React: React, ReactDOM: ReactDOM };
