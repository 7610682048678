var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonStripePayDefault from '../buttons/ButtonStripePayDefault';
import ButtonWeChatPayment from '../buttons/ButtonWeChatPayment';
import StripePaymentButtons from '../buttons/StripePaymentButtons';
import sliceStripeForm from '../slices/sliceStripeForm';
import { CreditCardImages } from '../components';
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useStripe, useElements, } from '@stripe/react-stripe-js';
import { loadStripe, } from '@stripe/stripe-js';
import { LoadingTemplate } from '../../loading';
import CARD_ELEMENT_OPTIONS from '../utilities/CARD_ELEMENT_OPTIONS';
import { Col, FormGroup, Row } from 'reactstrap';
function InnerForm(props) {
    var doStripePayment = props.doStripePayment, doCheckIfWeChatPaymentIsAccepted = props.doCheckIfWeChatPaymentIsAccepted, doStripePaymentRequestForButton = props.doStripePaymentRequestForButton, doStripePaymentRequestForWeChat = props.doStripePaymentRequestForWeChat, hideLabels = props.hideLabels, invoice = props.invoice, isCart = props.isCart, PaymentButton = props.PaymentButton, _a = props.showCardIcons, showCardIcons = _a === void 0 ? false : _a;
    var dispatch = useDispatch();
    var _b = useSelector(function (s) { return s.stateStripeForm; }), acceptedPaymentMethods = _b.acceptedPaymentMethods, loading = _b.loading, loadingPaymentRequestButton = _b.loadingPaymentRequestButton, loadingWeChatPayment = _b.loadingWeChatPayment;
    var inputClass = 'form-control p-t-8 stripe-form-control';
    var stripe = useStripe();
    var elements = useElements();
    function paymentStart(e) {
        var card = elements.getElement(CardNumberElement);
        dispatch(sliceStripeForm.actions.actionStripePaymentStart());
        e.preventDefault();
        if (!stripe || !elements) {
            dispatch(sliceStripeForm.actions.actionStripePaymentEnd());
            return;
        }
        doStripePayment({ card: card, invoice: invoice, isCart: isCart, stripe: stripe });
    }
    var weChatPayActive = acceptedPaymentMethods.wechat_pay_payments === 'active';
    React.useEffect(function () {
        if (stripe && doCheckIfWeChatPaymentIsAccepted) {
            doCheckIfWeChatPaymentIsAccepted(stripe);
        }
    }, [stripe]);
    return (React.createElement(React.Fragment, null, loadingPaymentRequestButton ?
        React.createElement("div", { style: { alignItems: 'center' } },
            React.createElement("div", { style: {
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '1rem',
                    textAlign: 'center'
                } },
                React.createElement("div", { className: 'spinner-merchi-small' })),
            React.createElement("p", { style: { fontSize: '14px', fontWeight: 600, textAlign: 'center' } }, "Processing payment"))
        :
            React.createElement(React.Fragment, null,
                Boolean(invoice && doStripePaymentRequestForButton) &&
                    React.createElement(StripePaymentButtons, { doPaymentRequest: function (event) {
                            return doStripePaymentRequestForButton(stripe, event);
                        }, invoice: invoice }),
                Boolean(doStripePaymentRequestForWeChat && weChatPayActive) &&
                    React.createElement(ButtonWeChatPayment, { loading: loadingWeChatPayment, doPaymentRequest: doStripePaymentRequestForWeChat ?
                            function () { return doStripePaymentRequestForWeChat(stripe); } :
                            console.log }),
                React.createElement("form", { onSubmit: paymentStart },
                    !hideLabels &&
                        React.createElement("label", null, "Card details"),
                    React.createElement(FormGroup, null,
                        React.createElement(CardNumberElement, __assign({ className: inputClass }, CARD_ELEMENT_OPTIONS))),
                    React.createElement(Row, null,
                        React.createElement(Col, { md: '6' },
                            React.createElement(FormGroup, null,
                                React.createElement(CardExpiryElement, __assign({ className: inputClass }, CARD_ELEMENT_OPTIONS)))),
                        React.createElement(Col, { md: '6' },
                            React.createElement(FormGroup, null,
                                React.createElement(CardCvcElement, __assign({ className: inputClass }, CARD_ELEMENT_OPTIONS))))),
                    showCardIcons &&
                        React.createElement("div", { className: 'form-stripe-card-safty-icons', style: { marginBottom: '1rem' } },
                            React.createElement(CreditCardImages, { height: '45' })),
                    PaymentButton ?
                        React.createElement(PaymentButton, __assign({}, props, { loading: loading })) :
                        React.createElement(ButtonStripePayDefault, __assign({}, props, { loading: loading }))))));
}
function FormStripeCardFields(_a) {
    var btnColor = _a.btnColor, btnText = _a.btnText, doStripePayment = _a.doStripePayment, doCheckIfWeChatPaymentIsAccepted = _a.doCheckIfWeChatPaymentIsAccepted, doStripePaymentRequestForWeChat = _a.doStripePaymentRequestForWeChat, doStripePaymentRequestForButton = _a.doStripePaymentRequestForButton, hideLabels = _a.hideLabels, invoice = _a.invoice, isCart = _a.isCart, PaymentButton = _a.PaymentButton, showCardIcons = _a.showCardIcons, stripePubKey = _a.stripePubKey;
    return stripePubKey ? (React.createElement(Elements, { stripe: loadStripe(stripePubKey) },
        React.createElement(InnerForm, { btnColor: btnColor, btnText: btnText, doStripePayment: doStripePayment, doCheckIfWeChatPaymentIsAccepted: doCheckIfWeChatPaymentIsAccepted, doStripePaymentRequestForButton: doStripePaymentRequestForButton, doStripePaymentRequestForWeChat: doStripePaymentRequestForWeChat, hideLabels: hideLabels, invoice: invoice, isCart: Boolean(isCart), PaymentButton: PaymentButton, showCardIcons: showCardIcons }))) : React.createElement(LoadingTemplate, null);
}
export default FormStripeCardFields;
