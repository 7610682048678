var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { MERCHI_SDK } from './merchi-sdk';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { renderOptionCostIndication, renderSingleCostIndication, renderVariationCostIndication, showCurrencyAndCost } from './currency';
import { uploadNewFile } from './utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faCircleNotch, faFileImage, faFilePdf, faFolderOpen, faPlus, faTimes, } from '@fortawesome/free-solid-svg-icons';
export function validatorRequired(msg) {
    return { required: { error: msg,
            inputClass: 'form-input-error' } };
}
export var supportedContryCodeShippingList = [
    'AU', // australia
    'AE', // anited arab emirates
    'BH', // bahrain
    'CN', // china
    'DK', // denmark
    'ES', // spain
    'FR', // france
    'GB', // great Britan
    'HK', // hong kong
    'ID', // indonesia
    'IN', // india
    'IT', // italy
    'KR', // south korea
    'NC', // new caledonia
    'NZ', // new zealand
    'OM', // oman
    'PH', // philippines
    'PT', // portugal
    'QA', // qatar
    'SE', // sweden
    'SG', // singapore
    'TH', // thailand
    'US', // united states
    'ZA', // south africa
];
export var supportedContryCodeShippingListFullName = new MERCHI_SDK.Dictionary();
supportedContryCodeShippingListFullName.add('AU', 'Australia');
supportedContryCodeShippingListFullName.add('AE', 'United Arab Emirates');
supportedContryCodeShippingListFullName.add('BH', 'Bahrain');
supportedContryCodeShippingListFullName.add('CN', 'China');
supportedContryCodeShippingListFullName.add('DK', 'Denmark');
supportedContryCodeShippingListFullName.add('ES', 'Spain');
supportedContryCodeShippingListFullName.add('FR', 'France');
supportedContryCodeShippingListFullName.add('GB', 'United Kingdom');
supportedContryCodeShippingListFullName.add('HK', 'Hong Kong');
supportedContryCodeShippingListFullName.add('ID', 'Indonesia');
supportedContryCodeShippingListFullName.add('IN', 'India');
supportedContryCodeShippingListFullName.add('IT', 'Italy');
supportedContryCodeShippingListFullName.add('KR', 'South Korea');
supportedContryCodeShippingListFullName.add('NC', 'New Caledonia');
supportedContryCodeShippingListFullName.add('NZ', 'New Zealand');
supportedContryCodeShippingListFullName.add('OM', 'Oman');
supportedContryCodeShippingListFullName.add('PH', 'Philippines');
supportedContryCodeShippingListFullName.add('PT', 'Portugal');
supportedContryCodeShippingListFullName.add('QA', 'Qatar');
supportedContryCodeShippingListFullName.add('SE', 'Sweden');
supportedContryCodeShippingListFullName.add('SG', 'Singapore');
supportedContryCodeShippingListFullName.add('Th', 'Thailand');
supportedContryCodeShippingListFullName.add('US', 'United States');
supportedContryCodeShippingListFullName.add('ZA', 'South Africa');
export var supportedCountryPhoneCodes = new MERCHI_SDK.Dictionary();
supportedCountryPhoneCodes.add('AU', 'Australia (+61)');
supportedCountryPhoneCodes.add('AE', 'United Arab Emirates (+971)');
supportedCountryPhoneCodes.add('BH', 'Bahrain (+973)');
supportedCountryPhoneCodes.add('CN', 'China (+86)');
supportedCountryPhoneCodes.add('DK', 'Denmark (+45)');
supportedCountryPhoneCodes.add('ES', 'Spain (+34)');
supportedCountryPhoneCodes.add('FR', 'France (+33)');
supportedCountryPhoneCodes.add('GB', 'United Kingdom (+44)');
supportedCountryPhoneCodes.add('HK', 'Hong Kong (+852)');
supportedCountryPhoneCodes.add('ID', 'Indonesia (+62)');
supportedCountryPhoneCodes.add('IN', 'India (+91)');
supportedCountryPhoneCodes.add('IT', 'Italy (+39)');
supportedCountryPhoneCodes.add('KR', 'South Korea (+82)');
supportedCountryPhoneCodes.add('NC', 'New Caledonia (+687)');
supportedCountryPhoneCodes.add('NZ', 'New Zealand (+64)');
supportedCountryPhoneCodes.add('OM', 'Oman (+968)');
supportedCountryPhoneCodes.add('PH', 'Philippines (+63)');
supportedCountryPhoneCodes.add('PT', 'Portugal (+351)');
supportedCountryPhoneCodes.add('QA', 'Qatar (+974)');
supportedCountryPhoneCodes.add('SE', 'Sweden (+46)');
supportedCountryPhoneCodes.add('SG', 'Singapore (+65)');
supportedCountryPhoneCodes.add('TH', 'Thailand (+66)');
supportedCountryPhoneCodes.add('US', 'United States (+1)');
supportedCountryPhoneCodes.add('ZA', 'South Africa (+27)');
function isValue(value) {
    return value && value !== undefined && value !== null;
}
// validation rules
var hasValue = function (value) {
    return value !== null && value !== undefined;
};
var isEmpty = function (value) {
    return value === '' || value === undefined || value === null;
};
var isEmptyTrimed = function (value) {
    if (typeof value === 'string') {
        return value.trim() === '';
    }
    return true;
};
function isRequired(validators) {
    return validators && validators.hasOwnProperty('required');
}
function isTextOrNumberType(type) {
    return type === 'text' || type === 'number';
}
function isSelectType(type) {
    return type === 'select';
}
function isTextAreaType(type) {
    return type === 'textarea';
}
function isFileInputType(type) {
    return type === 'file';
}
function isCountryType(type) {
    return type === 'country';
}
function isRegionType(type) {
    return type === 'region';
}
function isDateType(type) {
    return type === 'date';
}
function isColorType(type) {
    return type === 'color';
}
function isPassword(type) {
    return type === 'password';
}
var inputValidations = {
    isDecimal: function (value) {
        return inputValidations.matchRegexp(value, /^[+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/i);
    },
    isEmail: function (value) {
        return inputValidations.matchRegexp(value, /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    },
    isEmpty: function (value) {
        return isEmpty(value);
    },
    isNumber: function (value) {
        return inputValidations.matchRegexp(value, /^-?[0-9]\d*(\d+)?$/i);
    },
    isPositive: function (value) {
        if (hasValue(value)) {
            return (inputValidations.isNumber(value) ||
                inputValidations.isDecimal(value)) && value >= 0;
        }
        return true;
    },
    isString: function (value) { return !isEmpty(value) || typeof value === 'string' || value instanceof String; },
    matchRegexp: function (value, regexp) {
        var validationRegexp = (regexp instanceof RegExp ? regexp : (new RegExp(regexp)));
        return (!hasValue(value) || isEmpty(value) || validationRegexp.test(value));
    },
    matchingInputValues: function (value, valueTwo) {
        return value === valueTwo;
    },
    maxNumber: function (value, max) {
        return !hasValue(value) || isEmpty(value) || parseInt(value, 10) <= parseInt(max, 10);
    },
    maxStringLength: function (value, length) {
        return inputValidations.isString(value) && value.length <= length;
    },
    minNumber: function (value, min) {
        return !hasValue(value) || isEmpty(value) || parseInt(value, 10) >= parseInt(min, 10);
    },
    minStringLength: function (value, length) {
        return isEmpty(value) || (inputValidations.isString(value) && value.length >= length);
    },
    required: function (value) {
        return !isEmpty(value);
    },
    trim: function (value) {
        return !isEmptyTrimed(value);
    },
};
function getAlerts(value, validators) {
    var alerts = [];
    Object.keys(validators).map(function (key, index) {
        var validator = validators[key];
        var rule = validator.rule;
        var validate = inputValidations[key];
        var isNotValid = validate ? rule ?
            !validate(value, rule) : !validate(value) : false;
        if (isNotValid) {
            alerts.push(validator);
        }
    });
    return alerts;
}
function getAlertClass(alerts) {
    var alertClasses = {
        inputAlert: '',
        textAlert: ''
    };
    var firstAlert = alerts && alerts.length > 0 ? alerts[0] : null;
    if (firstAlert) {
        alertClasses.textAlert = alerts[0].hasOwnProperty('textClass') ?
            alerts[0].textClass : 'input-error-text';
        alertClasses.inputAlert = alerts[0].hasOwnProperty('inputClass') ?
            alerts[0].inputClass : 'form-input-error';
    }
    return alertClasses;
}
function setInputClass(inputClass, alerts) {
    var alertClass = getAlertClass(alerts);
    return (inputClass ? inputClass : 'form-control ') +
        (' ' + alertClass.inputAlert);
}
function InputAlerts(props) {
    var alerts = props.alerts, _a = props.containerClass, containerClass = _a === void 0 ? 'input-alert-container-block' : _a;
    var htmlClass = getAlertClass(alerts);
    return alerts && alerts.length > 0 ?
        React.createElement("div", { className: containerClass }, alerts.map(function (alert, key) {
            var msg = alert && alert.error ?
                alert.error : alert.warning ?
                alert.warning : 'This field is invalid';
            return React.createElement("span", { key: key, className: "".concat(htmlClass.textAlert, " m-r-3") }, msg);
        })) : null;
}
export function FileSelectInput(props) {
    var attributes = props.attributes;
    var isDisabled = attributes.disabled;
    var placeholder = attributes.placeholder;
    var onChange = props.onChange;
    var supportedFileTypes = attributes.allowedFileTypesString ?
        attributes.allowedFileTypesString : '.jpeg, .jpg, .gif, .pdf, .png, .ai';
    return (React.createElement("div", { className: 'input-group' },
        React.createElement("input", { className: 'form-control selected-files',
            disabled: isDisabled,
            placeholder: placeholder,
            readOnly: true,
            type: 'text' }),
        !isDisabled ?
            React.createElement("div", { className: 'input-group-btn' },
                React.createElement("label", { className: 'btn btn-default browse-files-button' },
                    React.createElement(FontAwesomeIcon, { icon: faFolderOpen }),
                    "  Browse",
                    React.createElement("input", { accept: supportedFileTypes,
                        className: 'hide',
                        disabled: isDisabled,
                        multiple: attributes.allowFileMultiple,
                        name: attributes.name,
                        onChange: onChange,
                        type: 'file' }))) : null));
}
var ValidationForm = /** @class */ (function (_super) {
    __extends(ValidationForm, _super);
    function ValidationForm() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.children = [];
        _this.attachToForm = function (component) {
            if (_this.children.indexOf(component) === -1) {
                _this.children.push(component);
            }
        };
        _this.detachFromForm = function (component) {
            var componentPos = _this.children.indexOf(component);
            if (componentPos !== -1) {
                _this.children = _this.children.slice(0, componentPos)
                    .concat(_this.children.slice(componentPos + 1));
            }
        };
        _this.getChildContext = function () { return ({
            form: {
                attachToForm: _this.attachToForm,
                detachFromForm: _this.detachFromForm,
            },
        }); };
        _this.allChildrenValid = function () {
            var self = _this;
            var childrenArray = Array.isArray(self.children) ? self.children : [self.children];
            var valid = true;
            return Promise.all(childrenArray.map(function (input) { return __awaiter(_this, void 0, void 0, function () {
                var isVal;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            input.validate(null);
                            return [4 /*yield*/, input.isValid()];
                        case 1:
                            isVal = _a.sent();
                            if (!isVal) {
                                valid = false;
                            }
                            return [2 /*return*/];
                    }
                });
            }); })).then(function () {
                return valid;
            });
        };
        _this.allChildrenTryValidate = function () {
            var childrenArray = Array.isArray(_this.children) ? _this.children : [_this.children];
            return Promise.all(childrenArray.map(function (input) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, input.validate(null)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); }));
        };
        _this.clearInputValues = function (complete) {
            var inputsValidated = 0;
            var self = _this;
            var childrenArray = Array.isArray(self.children) ? self.children : [self.children];
            childrenArray.forEach(function (input) {
                input.resetValue();
                inputsValidated++;
                if (inputsValidated === childrenArray.length && complete) {
                    complete();
                }
            });
        };
        _this.isFormValid = function () {
            var self = _this;
            return _this.allChildrenTryValidate().then(function () {
                return self.allChildrenValid().then(function (valid) {
                    return valid;
                });
            });
        };
        _this.checkIsFormValid = function () {
            return _this.allChildrenValid().then(function (isValid) {
                return isValid;
            });
        };
        _this.submit = function () {
            _this.allChildrenTryValidate().then(function () {
                _this.allChildrenValid().then(function (result) {
                    if (result) {
                        if (_this.props.onSubmit) {
                            _this.props.onSubmit();
                        }
                    }
                });
            });
        };
        _this.submitEvent = function (event) {
            if (event) {
                event.preventDefault();
            }
            _this.submit();
        };
        return _this;
    }
    ValidationForm.prototype.render = function () {
        var _a = this.props, onSubmit = _a.onSubmit, onError = _a.onError, children = _a.children, rest = __rest(_a, ["onSubmit", "onError", "children"]);
        return (React.createElement("form", __assign({}, rest, { onSubmit: this.submitEvent }), children));
    };
    /*
      This component allows users to build a form which will validate child
      inputs.
  
      Usage example:
  
      <ValidationForm
        onSubmit={(this as any).submitGo}>
        <ValidationElement
          validators={
            {required: {error: 'This field must be filled in'}}} />
        <button type='submit'>Submit</button>
      </ValidationForm>
  
      See ValidationElement comments for instructions on how to use
      ValidationElement inputs.
  
    */
    ValidationForm.childContextTypes = {
        form: PropTypes.object,
    };
    return ValidationForm;
}(React.Component));
export { ValidationForm };
var ValidationElement = /** @class */ (function (_super) {
    __extends(ValidationElement, _super);
    function ValidationElement(props) {
        var _this = _super.call(this, props) || this;
        _this.onChange = function (e) {
            var value = e.target.value;
            var validators = _this.props.validators ?
                _this.props.validators : {};
            var alerts = getAlerts(value, validators);
            _this.props.onChange(e);
            _this.setState({
                alerts: alerts,
                checked: true,
                value: value
            });
        };
        _this.countryOrRegionChange = function (value) {
            var validators = _this.props.validators ?
                _this.props.validators : {};
            var alerts = getAlerts(value, validators);
            _this.props.onChange(value);
            _this.setState({
                alerts: alerts,
                checked: true,
                value: value
            });
        };
        _this.dateChange = function (date) {
            var validators = _this.props.validators ?
                _this.props.validators : {};
            var alerts = getAlerts(date, validators);
            _this.setState({
                alerts: alerts,
                checked: true
            });
            _this.props.onChange(date);
        };
        _this.configure = function () {
            if (_this.context.form) {
                _this.context.form.attachToForm(_this);
            }
        };
        _this.isRequired = function () {
            var validators = _this.props.validators;
            return validators && validators.hasOwnProperty('required');
        };
        _this.isDateSelect = function () {
            return isDateType(_this.props.type);
        };
        _this.validate = function (complete) {
            var value = _this.isDateSelect() ?
                _this.state.selectedDate : _this.state.value;
            var validators = _this.props.validators ?
                _this.props.validators : {};
            var alerts = getAlerts(value, validators);
            _this.setState({
                alerts: alerts
            }, function () {
                if (complete) {
                    complete();
                }
            });
        };
        _this.errorInAlerts = function () {
            return Promise.all(_this.state.alerts.map(function (alert) {
                return alert.error;
            })).then(function (errors) {
                return errors && errors.length > 0 && errors[0] ? errors : [];
            });
        };
        _this.isValid = function () {
            var self = _this;
            return self.errorInAlerts().then(function (result) {
                return result.length === 0 && self.state.checked;
            });
        };
        _this.resetValue = function () {
            var attributes = _this.props.attributes;
            var defaultVal = _this.props.defaultValue;
            var valueProp = attributes && attributes.value ?
                attributes.value : null;
            var defaultValue = attributes && isValue(attributes.defaultValue) ?
                attributes.defaultValue : isValue(defaultVal) ? defaultVal : valueProp;
            _this.setState({ value: defaultValue });
        };
        var autoSet = props.autoSet, attributes = props.attributes, defaultValue = props.defaultValue, selectedDate = props.selectedDate, validators = props.validators;
        var valueProp = attributes && attributes.value ?
            attributes.value : null;
        var dV = attributes && isValue(attributes.defaultValue) ?
            defaultValue : isValue(defaultValue) ? defaultValue : valueProp;
        _this.state = {
            alerts: [],
            autoSet: autoSet,
            checked: dV || selectedDate || !isRequired(validators),
            selectedDate: selectedDate,
            value: isValue(dV) ? dV : '',
        };
        return _this;
    }
    ValidationElement.prototype.componentWillReceiveProps = function (nextProps) {
        var _this = this;
        var inputType = nextProps.type;
        if (nextProps.alerts !== this.props.alerts) {
            if (isFileInputType(nextProps.type) && this.state.value) {
                this.setState({ value: null });
            }
            this.setState({ alerts: nextProps.alerts });
        }
        if ((isTextOrNumberType(inputType) ||
            isCountryType(inputType) ||
            isRegionType(inputType) ||
            isSelectType(inputType)) && nextProps.autoSet) {
            this.setState({
                autoSet: nextProps.autoSet,
                checked: true,
                value: nextProps.defaultValue
            }, function () {
                _this.validate(null);
            });
        }
        if (isDateType(inputType) &&
            this.state.selectedDate !== nextProps.selectedDate) {
            this.setState({
                checked: true,
                selectedDate: nextProps.selectedDate
            });
        }
        if (nextProps.clearValue) {
            this.setState({
                alerts: [],
                checked: true,
                value: ''
            });
        }
    };
    ValidationElement.prototype.componentWillUnmount = function () {
        if (this.context.form) {
            this.context.form.detachFromForm(this);
        }
    };
    ValidationElement.prototype.componentDidMount = function () {
        this.configure();
    };
    ValidationElement.prototype.render = function () {
        var _a = this.props, alertsContainerClass = _a.alertsContainerClass, _b = _a.alertOutsideContainer, alertOutsideContainer = _b === void 0 ? false : _b, attributes = _a.attributes, children = _a.children, _c = _a.containerClass, containerClass = _c === void 0 ? 'form-group' : _c, country = _a.country, defaultValue = _a.defaultValue, disabled = _a.disabled, id = _a.id, inputClass = _a.inputClass, label = _a.label, name = _a.name, optionElementsArray = _a.optionElementsArray, options = _a.options, placeholder = _a.placeholder, prependElement = _a.prependElement, readOnly = _a.readOnly, rows = _a.rows, step = _a.step, style = _a.style, type = _a.type, whitelist = _a.whitelist;
        var _d = this.state, alerts = _d.alerts, autoSet = _d.autoSet, value = _d.value;
        var inputType = type;
        var isTextOrNumber = isTextOrNumberType(inputType);
        var isSelect = isSelectType(inputType);
        var isTypePassword = isPassword(inputType);
        var attrs = attributes ? attributes :
            { id: id ? id : undefined,
                name: name,
                placeholder: placeholder ? placeholder : undefined,
                rows: rows ? rows : undefined,
                style: style };
        var optionsChildren = optionElementsArray ? optionElementsArray : [];
        if (isSelect) {
            if (options) {
                options.each(function (key, optionValue) {
                    optionsChildren.push(React.createElement("option", { value: key, key: key }, optionValue));
                });
            }
            attrs.value = value;
        }
        if (isTextAreaType(inputType)) {
            if (isValue(defaultValue)) {
                attrs.value = value;
            }
        }
        if (isTextOrNumber || isColorType(inputType)) {
            attrs.type = inputType;
            if (isValue(defaultValue)) {
                attrs.value = value;
            }
            if (autoSet) {
                attrs.value = value;
            }
        }
        if (isTypePassword) {
            attrs.type = inputType;
        }
        attrs.className = setInputClass(inputClass, alerts);
        if (readOnly) {
            attrs.readOnly = readOnly;
        }
        if (disabled) {
            attrs.disabled = disabled;
        }
        if (step) {
            attrs.step = step;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: containerClass },
                prependElement,
                children,
                label ? React.createElement("label", null, label) : null,
                isTextOrNumber || isTypePassword || isColorType(inputType) ?
                    React.createElement("input", __assign({}, attrs, { onChange: this.onChange })) : null,
                isSelect &&
                    React.createElement("select", __assign({}, attrs, { onChange: this.onChange }), optionsChildren),
                isTextAreaType(inputType) &&
                    React.createElement("textarea", __assign({}, attrs, { onChange: this.onChange })),
                isFileInputType(inputType) &&
                    React.createElement(FileSelectInput, { attributes: attrs, onChange: this.onChange }),
                isCountryType(inputType) &&
                    React.createElement(CountryDropdown, { whitelist: whitelist, classes: attrs.className, valueType: 'short', value: defaultValue, name: name, id: attrs.id, onChange: this.countryOrRegionChange }),
                isRegionType(inputType) &&
                    React.createElement(RegionDropdown, { country: country, value: defaultValue, classes: attrs.className, countryValueType: 'short', name: name, valueType: 'short', id: attrs.id, onChange: this.countryOrRegionChange }),
                !alertOutsideContainer &&
                    React.createElement(InputAlerts, { alerts: this.state.alerts, alertsContainerClass: alertsContainerClass })),
            alertOutsideContainer &&
                React.createElement(InputAlerts, { alerts: this.state.alerts, alertsContainerClass: alertsContainerClass })));
    };
    /*
  
      The 'validators' attribute of this component takes an object of
      validation settings. The key is used to reference validation functions
      from the 'inputValidations' object. Each key value of validators should
      be an object. Inside this object the 'error' and 'rule' keys are
      expected, however the 'rule' key is only required if the corresponding
      'inputValidations' function requires a rule as an extra argument.
  
      Usage example:
  
      <ValidationElement
        validators={
          {required: {error: 'This field must be filled in'},
           maxNumber: {
             rule: 5,
             error: 'The maximum number for this input is 5',
             textClass: 'input-error-text',
             inputClass 'form-input-error',
           }}} />
  
    */
    ValidationElement.contextTypes = {
        form: PropTypes.object,
    };
    return ValidationElement;
}(React.Component));
export { ValidationElement };
export function ValidationInputText(props) {
    return React.createElement(ValidationElement, __assign({ type: 'text' }, props));
}
export function ValidationInputNumber(props) {
    return React.createElement(ValidationElement, __assign({ type: 'number' }, props));
}
export function ValidationTextarea(props) {
    return React.createElement(ValidationElement, __assign({ type: 'textarea' }, props));
}
export function ValidationSelect(props) {
    return React.createElement(ValidationElement, __assign({ type: 'select' }, props));
}
export function ValidationCountry(props) {
    return React.createElement(ValidationElement, __assign({ type: 'country' }, props));
}
export function ValidationReigon(props) {
    return React.createElement(ValidationElement, __assign({ type: 'region' }, props));
}
export function ValidationDateSelect(props) {
    return React.createElement(ValidationElement, __assign({ type: 'date' }, props));
}
export function ValidationColorSelect(props) {
    return React.createElement(ValidationElement, __assign({ type: 'color' }, props));
}
export function ImageSelectLabelTemplate(props) {
    var product = props.product, variation = props.variation;
    var field = variation ? variation.variationField() : null;
    var currency = product ? product.productCurrency() : null;
    var onceOff = 0;
    var unitCost = 0;
    variation.selectedSelectableOptions().map(function (option) {
        if (option.onceOffCost()) {
            onceOff += option.onceOffCost();
        }
        if (option.unitCost()) {
            unitCost += option.unitCost();
        }
        return '';
    });
    return (React.createElement("label", null,
        field ? field.name() : '',
        ' ',
        renderSingleCostIndication(onceOff, currency, 'once off'),
        renderSingleCostIndication(unitCost, currency, 'per unit')));
}
export function VariationInputLabel(props) {
    var product = props.product, variation = props.variation;
    var field = variation ? variation.variationField() : null;
    var isSelectable = field && field.isSelectable();
    var isImageOrColorSelect = field && field.isImageSelectType() || field.isColourSelectType();
    return (React.createElement(React.Fragment, null, field &&
        React.createElement(React.Fragment, null, field.isInstructionsType() ?
            ''
            : isSelectable ?
                isImageOrColorSelect ? (React.createElement(ImageSelectLabelTemplate, { product: product, variation: variation })) : (React.createElement("label", null, field.name())) : (React.createElement(LabelTemplate, { product: product, variation: variation })))));
}
function SelectOption(props) {
    var option = props.option, product = props.product;
    var currency = product ? product.productCurrency() : null;
    var costs = currency ? renderOptionCostIndication(option, currency) : '';
    return (React.createElement("option", { value: option.optionId() },
        option.value(),
        " ",
        costs));
}
export function FileLoadingTemplate() {
    return (React.createElement("li", { className: 'list-inline-item file-upload-preview-item file-preivew' },
        React.createElement("div", { style: { width: '55px', margin: 'auto', padding: '10px 0px' } },
            React.createElement(FontAwesomeIcon, { icon: faCircleNotch, size: '4x', spin: true }),
            React.createElement("span", { className: 'title' }, "Loading..."))));
}
function inputValidationObject(rule, msg) {
    return !MERCHI_SDK.isUndefinedOrNull(rule) ?
        { rule: rule, error: msg } : false;
}
function variationFieldTextOrNumberOrColor(props) {
    var disabled = props.disabled, field = props.field, variation = props.variation;
    var fieldName = field.name();
    var min = field.fieldMin();
    var max = field.fieldMax();
    var validators = {};
    var required = field.required() ? { error: "".concat(fieldName, " is a required value.") } : false;
    var minError = inputValidationObject(min, "".concat(fieldName, " must be more than ").concat(min));
    var maxError = inputValidationObject(max, "".concat(fieldName, " must be less than ").concat(max));
    if (required) {
        validators.required = required;
    }
    if (minError) {
        validators.minNumber = minError;
    }
    if (maxError) {
        validators.maxNumber = maxError;
    }
    return (React.createElement(ValidationElement, { type: field.fieldInputTypeAttributeValue(), validators: validators, inputClass: 'form-control', attributes: {
            defaultValue: variation.value(),
            disabled: disabled,
            name: buildFieldName(props),
            placeholder: field.placeholder(),
            step: field.allowDecimal() ? 'any' : 1
        }, onChange: function (event) {
            if (!disabled) {
                variation.value(event.target.value);
            }
        } }));
}
function variationFieldTextArea(props) {
    var disabled = props.disabled, field = props.field, variation = props.variation;
    var validators = {};
    var required = Boolean(field.required()) ?
        { error: field.name() + ' is a required value.' } : false;
    var attributes = {
        defaultValue: variation.value(),
        disabled: disabled,
        name: buildFieldName(props),
        placeholder: field.placeholder(),
        rows: field.rows()
    };
    if (required) {
        validators.required = required;
    }
    return (React.createElement(ValidationElement, { validators: validators, attributes: attributes, type: 'textarea', inputClass: 'form-control', onChange: function (event) {
            if (!disabled) {
                variation.value(event.target.value);
            }
        } }));
}
function variationFieldSelect(props) {
    var disabled = props.disabled, product = props.product, updateJob = props.updateJob, variation = props.variation;
    var attributes = {
        disabled: disabled,
        name: buildFieldName(props),
        value: variation.valueArray()[0] ? variation.valueArray()[0] : null,
    };
    var options = [];
    variation.selectableOptions().map(function (option, index) {
        options.push(React.createElement(SelectOption, { product: product, option: option, key: index }));
    });
    return (React.createElement(ValidationElement, { type: 'select', optionElementsArray: options, inputClass: 'form-control', attributes: attributes, onChange: function (event) {
            if (!disabled) {
                variation.value(event.target.value);
                updateJob(true);
            }
        } }));
}
function setCheckBoxOrRadioValue(field, variation, option, event) {
    if (field.isRadioType()) {
        variation.valueArray([option.optionId()]);
    }
    if (field.isCheckboxType()) {
        if (event.target.checked) {
            variation.addOptionToValueArray(option.optionId());
        }
        else {
            variation.removeOptionFromValueArray(option.optionId());
        }
    }
}
function CheckBoxOrRadioInputOption(props) {
    var disabled = props.disabled, field = props.field, option = props.option, product = props.product, variation = props.variation, updateJob = props.updateJob;
    var currency = product.productCurrency();
    function updateVariationValue(event) {
        if (!disabled) {
            setCheckBoxOrRadioValue(field, variation, option, event);
            updateJob(true);
        }
    }
    return (React.createElement("label", { className: 'form-check-radio-option' },
        React.createElement("input", { checked: variation.optionIsChecked(option.optionId()), disabled: disabled, onChange: updateVariationValue, type: field.fieldInputTypeAttributeValue(), value: option.value() }),
        ' ',
        " ",
        option.value(),
        " ",
        currency ? renderOptionCostIndication(option, currency) : null));
}
function variationFieldCheckBoxOrRadio(props) {
    var disabled = props.disabled, field = props.field, product = props.product, updateJob = props.updateJob, variation = props.variation;
    return (React.createElement("div", { className: 'col-lg-12' }, variation.selectableOptions().map(function (option, index) {
        return React.createElement(CheckBoxOrRadioInputOption, { field: field, variation: variation, updateJob: updateJob, option: option, product: product, disabled: disabled, key: index });
    })));
}
function FileLoadedTemplate(props) {
    var disabled = props.disabled, file = props.file, files = props.files, updateJob = props.updateJob;
    var imagePreviewTypes = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png'];
    var previewImage = React.createElement("img", { style: { maxWidth: '100%', maxHeight: '200px', display: 'block', margin: 'auto' }, src: file.viewUrl() });
    if (file.mimetype() === 'application/pdf') {
        previewImage =
            React.createElement("span", { className: 'm-b-30' },
                React.createElement(FontAwesomeIcon, { icon: faFilePdf, size: '4x' }));
    }
    else if (imagePreviewTypes.indexOf(file.mimetype()) === -1) {
        previewImage =
            React.createElement("span", { className: 'm-b-30' },
                React.createElement(FontAwesomeIcon, { icon: faFileImage, size: '4x' }));
    }
    return (React.createElement("li", { className: 'list-inline-item file-upload-preview-item file-upload-loaded' },
        previewImage,
        React.createElement("span", { className: 'title' }, file.name()),
        !disabled &&
            React.createElement("button", { className: 'btn btn-danger btn-block btn-xs file-delete-btn', onClick: function () {
                    files.splice(props.index, 1);
                    updateJob(false);
                } },
                React.createElement(FontAwesomeIcon, { icon: faTimes }))));
}
function FilePreviewContainer(props) {
    var disabled = props.disabled, _a = props.files, files = _a === void 0 ? [] : _a, updateJob = props.updateJob;
    return (React.createElement("ul", { className: 'list-inline show-preview-container' }, files.sort(function (a, b) {
        var aId = a.id();
        var bId = b.id();
        if (!aId) {
            return -1;
        }
        if (aId && bId && (aId > bId)) {
            return 0;
        }
        return 1;
    }).
        map(function (file, index) {
        return file.id() ?
            React.createElement(FileLoadedTemplate, { disabled: disabled, index: index, file: file, files: files, updateJob: updateJob, key: index })
            : React.createElement(FileLoadingTemplate, { key: index });
    })));
}
function fileInputDisplayValue(placeholder, files) {
    var length = files ? files.length : 0;
    return length === 0 ? placeholder :
        length === 1 ? '1 file uploaded' : "".concat(length, " files uploaded");
}
function variationFieldFileSelect(props) {
    var disabled = props.disabled, field = props.field, updateJob = props.updateJob, variation = props.variation;
    var files = variation.variationFiles() ?
        variation.variationFiles() : variation.variationFiles([]);
    var fileLength = files.length;
    var requiredError = { error: field.name() + ' is a required value.' };
    var required = field.required() && (!fileLength || fileLength < 1) ?
        requiredError : undefined;
    var validators = required ? { required: required } : {};
    var placeholder = field.placeholder() ?
        fileInputDisplayValue(field.placeholder(), files) : '';
    function updateVariationFiles(file, clearOld) {
        var specFiles = variation.variationFiles();
        specFiles.push(file);
        if (clearOld) {
            MERCHI_SDK.removeUnstoredFiles(specFiles, false);
        }
        updateJob(false);
    }
    function fileChnage(event) {
        if (!disabled) {
            uploadNewFile(event, updateVariationFiles);
        }
    }
    return (React.createElement("div", null,
        React.createElement(ValidationElement, { type: 'file', attributes: {
                allowFileMultiple: field.allowFileMultiple(),
                allowedFileTypesString: field.allowedFileTypesString(),
                disabled: disabled,
                name: buildFieldName(props),
                placeholder: placeholder,
            }, validators: validators, onChange: fileChnage, fileCount: fileLength }),
        field.showFilePreview() &&
            React.createElement(FilePreviewContainer, { updateJob: updateJob, files: variation.variationFiles(), disabled: disabled })));
}
function ImageSelectOption(props) {
    var disabled = props.disabled, field = props.field, option = props.option, updateJob = props.updateJob, variation = props.variation;
    var linkedFile = option.linkedFile();
    var isChecked = variation.optionIsChecked(option.optionId());
    return (React.createElement("div", { className: 'image-select-option-item-container', onClick: function (event) {
            if (!disabled) {
                if (field.multipleSelect()) {
                    if (variation.optionIsChecked(option.optionId())) {
                        variation.removeOptionFromValueArray(option.optionId());
                    }
                    else {
                        variation.addOptionToValueArray(option.optionId());
                    }
                }
                else {
                    variation.valueArray([option.optionId()]);
                }
                updateJob(true);
            }
        } },
        React.createElement("div", { className: "image-select-option ".concat(isChecked && 'image-checked') },
            isChecked && React.createElement(FontAwesomeIcon, { icon: faCheckSquare }),
            React.createElement("img", { src: linkedFile && linkedFile.viewUrl() ?
                    linkedFile.viewUrl() : null }),
            React.createElement("div", { className: 'image-select-title', "data-tip": true, "data-for": option.value() }, option.value()),
            React.createElement("span", null, option.value()),
            React.createElement("input", { className: 'hide',
                defaultChecked: isChecked,
                disabled: disabled,
                type: field.multipleSelect() ? 'checkbox' : 'radio' }))));
}
function variationFieldImageSelect(props) {
    var disabled = props.disabled, field = props.field, updateJob = props.updateJob, variation = props.variation;
    return (React.createElement("div", null, variation.selectableOptions().map(function (option, index) {
        return React.createElement(ImageSelectOption, { field: field, variation: variation, option: option, updateJob: updateJob, disabled: disabled, key: index });
    })));
}
function ColourSelectOption(props) {
    var disabled = props.disabled, field = props.field, option = props.option, updateJob = props.updateJob, variation = props.variation;
    var isChecked = variation.optionIsChecked(option.optionId());
    var pickerClass = "colour-picker-option ".concat(isChecked && 'colour-picker-checked');
    return (React.createElement("li", { className: 'colour-select-option-container', onClick: function () {
            if (!disabled) {
                if (field.multipleSelect()) {
                    if (variation.optionIsChecked(option.optionId())) {
                        variation.removeOptionFromValueArray(option.optionId());
                    }
                    else {
                        variation.addOptionToValueArray(option.optionId());
                    }
                }
                else {
                    variation.valueArray([option.optionId()]);
                }
                updateJob(true);
            }
        } },
        React.createElement("div", { className: pickerClass, style: { background: option.colour() } },
            isChecked && React.createElement(FontAwesomeIcon, { icon: faCheckSquare }),
            React.createElement("input", { className: 'hide',
                defaultChecked: isChecked,
                disabled: disabled,
                type: field.multipleSelect() ? 'checkbox' : 'radio' })),
        React.createElement("div", { className: 'colour-picker-title', "data-tip": true, "data-for": option.value() }, option.value()),
        React.createElement("span", null, option.value())));
}
function variationFieldColourSelect(props) {
    var disabled = props.disabled, field = props.field, updateJob = props.updateJob, variation = props.variation;
    return (React.createElement("ul", { className: 'list-inline' }, variation.selectableOptions().map(function (option, index) {
        return React.createElement(ColourSelectOption, { field: field, variation: variation, option: option, updateJob: updateJob, disabled: disabled, key: index });
    })));
}
function VariationFieldContainer(props) {
    // at first render (variation built by product field) use product field
    // in the once the variation is exists, use the variation field which is
    // likely to be a clone of product field.
    var field = props.field, product = props.product, variation = props.variation;
    var input = variationFieldTextOrNumberOrColor(props);
    var containerClass = '';
    if (field.options()) {
        MERCHI_SDK.sortArrayByObjectKey(field.options(), 'position');
    }
    if (field.isTextAreaType()) {
        input = variationFieldTextArea(props);
    }
    else if (field.isSelectType()) {
        input = variationFieldSelect(props);
    }
    else if (field.isFileInput()) {
        input = variationFieldFileSelect(props);
    }
    else if (field.isCheckboxOrRadio()) {
        input = variationFieldCheckBoxOrRadio(props);
    }
    else if (field.isImageSelectType()) {
        input = variationFieldImageSelect(props);
    }
    else if (field.isColourSelectType()) {
        input = variationFieldColourSelect(props);
    }
    else if (field.isInstructionsType()) {
        containerClass = 'form-instructions-container';
        input = React.createElement("p", null, field.name());
    }
    return (React.createElement("div", { className: "form-group ".concat(containerClass) },
        React.createElement(VariationInputLabel, { product: product, variation: variation }),
        input));
}
function VariationFields(props) {
    var disabled = props.disabled, fields = props.fields, product = props.product, updateJob = props.updateJob, variations = props.variations;
    var fieldsArray = fields ?
        MERCHI_SDK.sortArrayByObjectKey(fields, 'position') : [];
    return (React.createElement("div", null, fieldsArray.map(function (field, index) {
        var variation = variations[index] ? variations[index] : null;
        if (!variation) {
            variation = field.buildEmptyVariation();
            variations.push(variation);
        }
        return (React.createElement(VariationFieldContainer, { product: product, updateJob: updateJob, variation: variation, field: field, key: index, disabled: disabled }));
    })));
}
export function Variations(props) {
    var disabled = props.disabled, job = props.job, product = props.product, updateJob = props.updateJob;
    return (React.createElement("div", { className: 'independent-variations' },
        React.createElement(VariationFields, { product: product, fields: product.independentVariationFields(), variations: job.variations(), updateJob: function (wantUpdate) { return updateJob(job, wantUpdate); }, disabled: Boolean(disabled) })));
}
function buildFieldName(props) {
    var field = props.field;
    var id = field ? field.id() : '0';
    var type = field ? field.fieldType() : '0';
    var name = 'variation-field-' + id + '-' + type;
    if (!MERCHI_SDK.isUndefinedOrNull(props.groupIndex)) {
        name += '-' + props.groupIndex;
    }
    return name;
}
export function LabelTemplate(props) {
    var labelName = props.labelName, product = props.product, variation = props.variation;
    var field = variation ? variation.variationField() : null;
    var currency = product ? product.productCurrency() : null;
    var labelText = labelName ? labelName : '';
    return (React.createElement("label", null,
        field ? field.name() : labelText,
        ' ',
        field && currency ? renderVariationCostIndication(variation, currency) : ''));
}
function GroupQuantityInput(props) {
    var disabled = props.disabled, group = props.group, groupIndex = props.groupIndex, job = props.job, product = props.product, updateJob = props.updateJob;
    var fieldName = 'Group quantity';
    var fieldInputName = "group-quantity-".concat(groupIndex);
    var isDisabled = disabled;
    var inputClass = fieldInputName + ' form-control';
    var validators = { required: { error: fieldName + ' is a required.' } };
    var quantAttributes = {
        className: inputClass,
        defaultValue: group.quantity(),
        min: product.minimum(),
        name: fieldInputName,
        placeholder: fieldName,
        required: true,
        step: '1',
        type: 'number',
    };
    if (isDisabled) {
        quantAttributes.disabled = isDisabled;
    }
    function updateQuantity(event) {
        if (!isDisabled) {
            var quant = event.target.value === '' ? 0 : event.target.value;
            job.variationsGroups()[groupIndex]['quantity'](quant);
            updateJob(job, true);
        }
    }
    return (React.createElement("div", { className: 'form-group quantity-total-container' },
        React.createElement(LabelTemplate, { labelName: fieldName }),
        props.withValidation ? (React.createElement(ValidationElement, { validators: validators, attributes: quantAttributes, type: 'number', inputClass: inputClass, onChange: updateQuantity })) : (React.createElement("input", __assign({}, quantAttributes, { onChange: updateQuantity })))));
}
function GroupVariation(props) {
    var disabled = props.disabled, fetchingPrice = props.fetchingPrice, fields = props.fields, group = props.group, groups = props.groups, groupIndex = props.groupIndex, job = props.job, product = props.product, updateJob = props.updateJob, withValidation = props.withValidation;
    var isResell = product.isResell();
    var deleteButton = null;
    var addNewGroupButton = null;
    if (!disabled && groups.length > 1) {
        deleteButton =
            React.createElement("button", { className: 'btn btn-danger', onClick: function () {
                    groupKeys.splice(groupIndex, 1);
                    groups.splice(groupIndex, 1);
                    updateJob(true);
                } },
                React.createElement(FontAwesomeIcon, { icon: faTimes }),
                " Delete This Group");
    }
    if (!disabled && groups.length === groupIndex + 1) {
        addNewGroupButton =
            React.createElement("button", { className: 'btn btn-default pull-right', onClick: function () {
                    var _group = new MERCHI_SDK.VariationsGroup().
                        groupCost(0).
                        quantity(isResell ? 1 : 0).
                        variations([]);
                    groupKeys.push(_group.temporaryId);
                    groups.push(_group);
                    updateJob(true);
                } },
                React.createElement(FontAwesomeIcon, { icon: faPlus }),
                " Add New Group");
    }
    return (React.createElement("div", { className: 'variation-group' },
        !isResell &&
            React.createElement(GroupQuantityInput, { product: product, job: job, group: group, groupIndex: groupIndex, updateJob: updateJob, disabled: disabled, withValidation: withValidation }),
        React.createElement(VariationFields, { product: product, updateJob: updateJob, variations: group.variations() ? group.variations() : group.variations([]), fields: fields, disabled: disabled }),
        React.createElement("div", { className: 'variation-group-total m-b-20' },
            React.createElement("span", null,
                isResell ? 'Unit' : 'Group',
                " cost ",
                fetchingPrice ? (React.createElement(FontAwesomeIcon, { icon: faCircleNotch, spin: true })) : showCurrencyAndCost(product, group.groupCost()))),
        React.createElement("div", { className: 'form-group clearfix' },
            deleteButton,
            " ",
            addNewGroupButton)));
}
var groupKeys = [];
export function Groups(props) {
    var disabled = props.disabled, fetchingPrice = props.fetchingPrice, job = props.job, product = props.product, updateJob = props.updateJob, withValidation = props.withValidation;
    var groupVariationFields = product.groupVariationFields();
    var groups = job.variationsGroups() ? job.variationsGroups() : [];
    var isResell = product.isResell();
    var grousTemplate = '';
    if (product.hasGroupVariationFields()) {
        if (groupKeys.length === 0) {
            groups.map(function (g) { return groupKeys.push(g.temporaryId); });
        }
        if (!job.hasVariationsGroups()) {
            var _group = new MERCHI_SDK.VariationsGroup().
                groupCost(0).quantity(isResell ? 1 : 0).variations([]);
            groupKeys.push(_group.temporaryId);
            groups.push(_group);
        }
        grousTemplate =
            groups.map(function (group, index) {
                return React.createElement(GroupVariation, { groups: groups, group: group, product: product, job: job, updateJob: function (wantUpdate) { return updateJob(job, wantUpdate); }, fetchingPrice: fetchingPrice, fields: groupVariationFields, disabled: Boolean(disabled), groupIndex: index, key: groupKeys[index], withValidation: withValidation });
            });
    }
    return (React.createElement("div", { className: 'product-variation-groups' }, grousTemplate));
}
