var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { productFeatureImageUrl, productNotFoundUrl } from '../utilities';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
function ProductImageThumbnails(props) {
    var activeImgSrc = props.activeImgSrc, images = props.images, showPreview = props.showPreview, flexRow = props.flexRow;
    var maxImages = 7;
    var cappedImages = images ? images.slice(0, maxImages) : [];
    return (React.createElement("div", { className: "d-flex merchi-product-thumbnails ".concat(flexRow === true ? 'flex-row' : 'flex-column') }, cappedImages.map(function (image, index) { return (React.createElement("div", { className: activeImgSrc === image.viewUrl ? 'merchi-product-tb merchi-product-tb-active' : 'merchi-product-tb', key: index, onClick: function () { return showPreview(image.viewUrl); } },
        React.createElement("img", { className: 'merchi-product-thumbnail-img merchi-product-merchi-product-fit-image', src: image.viewUrl, alt: "".concat(image.name, "-thumbnail") }))); })));
}
export default function ProductImages(_a) {
    var product = _a.product;
    var _b = __read(React.useState(productFeatureImageUrl(product, productNotFoundUrl)), 2), previewImgSrc = _b[0], setPreviewImgSrc = _b[1];
    return (React.createElement("div", { className: 'merchi-product-preview-container' },
        React.createElement("div", { className: 'd-flex flex-column pr-1' },
            React.createElement(ProductImageThumbnails, { activeImgSrc: previewImgSrc, images: product.images, showPreview: setPreviewImgSrc })),
        React.createElement("div", { className: 'merchi-product-preview-container-main-image' },
            React.createElement(Zoom, null,
                React.createElement("div", { className: 'merchi-product-product-pic' },
                    React.createElement("img", { src: previewImgSrc ? previewImgSrc : productNotFoundUrl }))))));
}
