var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Merchi } from 'merchi_sdk_ts';
import sliceStripeAlerts from './slices/sliceStripeAlerts';
import sliceStripeForm from './slices/sliceStripeForm';
var alertError = sliceStripeAlerts.actions.alertError;
export function actionAlertError(dispatch, message) {
    dispatch(alertError(message));
}
var merchi = new Merchi();
var _a = sliceStripeForm.actions, actionFetchAcceptedPaymentMethods = _a.actionFetchAcceptedPaymentMethods, actionFetchAcceptedPaymentMethodsDone = _a.actionFetchAcceptedPaymentMethodsDone, actionStripePaymentEnd = _a.actionStripePaymentEnd, actionStripePaymentRequestButtonEnd = _a.actionStripePaymentRequestButtonEnd, actionStripePaymentRequestButtonStart = _a.actionStripePaymentRequestButtonStart, actionStripeWeChatPaymentEnd = _a.actionStripeWeChatPaymentEnd, actionStripeWeChatPaymentStart = _a.actionStripeWeChatPaymentStart;
function initPaymentIntentUrl(isCart, id) {
    return "/stripe/payment_intent/".concat(isCart ? 'cart' : 'invoice', "/").concat(id, "/");
}
export function createPaymentIntent(action) {
    return __awaiter(this, void 0, void 0, function () {
        var invoice, isCart, paymentMethodType, fetchOptions;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    invoice = action.invoice, isCart = action.isCart, paymentMethodType = action.paymentMethodType;
                    fetchOptions = { method: 'GET' };
                    fetchOptions.query = [];
                    if (isCart) {
                        fetchOptions.query.push(['cart_token', invoice.token]);
                    }
                    else {
                        fetchOptions.query.push(['invoice_token', invoice.invoiceToken]);
                    }
                    if (paymentMethodType) {
                        fetchOptions.query.push(['payment_method_types', paymentMethodType]);
                    }
                    return [4 /*yield*/, merchi.authenticatedFetch(initPaymentIntentUrl(isCart, invoice.id), fetchOptions)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
function checkPaymentIntnetIsComplete(dispatch, invoice, isCart, callbackPaymentSuccess) {
    return __awaiter(this, void 0, void 0, function () {
        var invoiceJson, url, fetchOptions, r, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    invoiceJson = invoice;
                    url = "/stripe/payment_intent/invoice/complete/".concat(invoiceJson.id, "/");
                    fetchOptions = { method: 'GET' };
                    fetchOptions.query = [['invoice_token', invoiceJson.invoiceToken]];
                    if (isCart) {
                        fetchOptions.query = [['cart_token', invoiceJson.token]];
                        url = "/stripe/payment_intent/cart/complete/".concat(invoiceJson.id, "/");
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, merchi.authenticatedFetch(url, fetchOptions)];
                case 2:
                    r = _a.sent();
                    callbackPaymentSuccess(r.invoice);
                    dispatch(actionStripePaymentEnd());
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    actionAlertError(dispatch, e_1 instanceof Error ? e_1.message : String(e_1));
                    dispatch(actionStripePaymentEnd());
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function actionStripeCardFormSubmit(dispatch, action) {
    return __awaiter(this, void 0, void 0, function () {
        var callbackPaymentSuccess, card, invoice, isCart, stripe, intent, stripePayment, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    callbackPaymentSuccess = action.callbackPaymentSuccess, card = action.card, invoice = action.invoice, isCart = action.isCart, stripe = action.stripe;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 7, , 8]);
                    return [4 /*yield*/, createPaymentIntent({ isCart: isCart, invoice: invoice })];
                case 2:
                    intent = _a.sent();
                    return [4 /*yield*/, stripe.confirmCardPayment(intent.stripeClientSecret, { payment_method: { card: card } })];
                case 3:
                    stripePayment = _a.sent();
                    if (!stripePayment.error) return [3 /*break*/, 4];
                    actionAlertError(dispatch, stripePayment.error.message || 'Stripe connect unknown error');
                    dispatch(actionStripePaymentEnd());
                    return [3 /*break*/, 6];
                case 4: return [4 /*yield*/, checkPaymentIntnetIsComplete(dispatch, invoice, isCart, callbackPaymentSuccess)];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6:
                    ;
                    return [3 /*break*/, 8];
                case 7:
                    e_2 = _a.sent();
                    actionAlertError(dispatch, e_2 instanceof Error ? e_2.message : String(e_2));
                    dispatch(actionStripePaymentEnd());
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    });
}
export function actionStripePaymentButtonSubmit(dispatch, elements, event) {
    return __awaiter(this, void 0, void 0, function () {
        var callbackPaymentSuccess, invoice, isCart, stripe, complete, paymentMethod, intent, stripePayment, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    callbackPaymentSuccess = elements.callbackPaymentSuccess, invoice = elements.invoice, isCart = elements.isCart, stripe = elements.stripe;
                    complete = event.complete, paymentMethod = event.paymentMethod;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 7, , 8]);
                    dispatch(actionStripePaymentRequestButtonStart());
                    return [4 /*yield*/, createPaymentIntent({ isCart: isCart, invoice: invoice })];
                case 2:
                    intent = _a.sent();
                    return [4 /*yield*/, stripe.confirmCardPayment(intent.stripeClientSecret, { payment_method: paymentMethod.id }, { handleActions: false })];
                case 3:
                    stripePayment = _a.sent();
                    if (!stripePayment.error) return [3 /*break*/, 4];
                    actionAlertError(dispatch, stripePayment.error.message || 'Stripe unknown error');
                    // Report to the browser that the payment failed, prompting it to
                    // re-show the payment interface, or show an error message and close
                    // the payment interface.
                    complete('fail');
                    dispatch(actionStripePaymentRequestButtonEnd());
                    return [3 /*break*/, 6];
                case 4:
                    complete('success');
                    return [4 /*yield*/, checkPaymentIntnetIsComplete(dispatch, invoice, isCart, callbackPaymentSuccess)];
                case 5:
                    _a.sent();
                    dispatch(actionStripePaymentRequestButtonEnd());
                    _a.label = 6;
                case 6:
                    ;
                    return [3 /*break*/, 8];
                case 7:
                    e_3 = _a.sent();
                    actionAlertError(dispatch, e_3 instanceof Error ? e_3.message : String(e_3));
                    dispatch(actionStripePaymentRequestButtonEnd());
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    });
}
export function actionStripeWeChatPaymentButtonSubmit(dispatch, action) {
    return __awaiter(this, void 0, void 0, function () {
        var callbackPaymentSuccess, invoice, isCart, stripe, intent, error, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    callbackPaymentSuccess = action.callbackPaymentSuccess, invoice = action.invoice, isCart = action.isCart, stripe = action.stripe;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 7, , 8]);
                    dispatch(actionStripeWeChatPaymentStart());
                    return [4 /*yield*/, createPaymentIntent({ invoice: invoice, isCart: isCart, paymentMethodType: 'wechat_pay' })];
                case 2:
                    intent = _a.sent();
                    return [4 /*yield*/, stripe.confirmWechatPayPayment(intent.stripeClientSecret, {
                            payment_method_options: {
                                wechat_pay: {
                                    client: 'web',
                                },
                            }
                        })];
                case 3:
                    error = (_a.sent()).error;
                    if (!error) return [3 /*break*/, 4];
                    actionAlertError(dispatch, error.message || 'Stripe unknown error');
                    // Report to the browser that the payment failed, prompting it to
                    // re-show the payment interface, or show an error message and close
                    // the payment interface.
                    dispatch(actionStripeWeChatPaymentEnd());
                    return [3 /*break*/, 6];
                case 4: return [4 /*yield*/, checkPaymentIntnetIsComplete(dispatch, invoice, isCart, callbackPaymentSuccess)];
                case 5:
                    _a.sent();
                    dispatch(actionStripeWeChatPaymentEnd());
                    _a.label = 6;
                case 6:
                    ;
                    return [3 /*break*/, 8];
                case 7:
                    e_4 = _a.sent();
                    actionAlertError(dispatch, e_4 instanceof Error ? e_4.message : String(e_4));
                    dispatch(actionStripeWeChatPaymentEnd());
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    });
}
export function actionCheckIfWeChatPaymentIsAccepted(dispatch, stripe, invoice) {
    return __awaiter(this, void 0, void 0, function () {
        var domain;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    domain = invoice.domain;
                    dispatch(actionFetchAcceptedPaymentMethods());
                    return [4 /*yield*/, merchi.authenticatedFetch("/companies/".concat(domain.company.id, "/stripe/payment_options/"), {}).then(function (r) { return dispatch(actionFetchAcceptedPaymentMethodsDone(r)); }).
                            catch(function () { return dispatch(actionFetchAcceptedPaymentMethodsDone({})); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
