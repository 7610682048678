var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faDownload, faDraftingCompass, faExclamationCircle, faHammer, faLink, faStopwatch, faTrashAlt, faTruck, faUsers, } from '@fortawesome/free-solid-svg-icons';
import { Button, ListGroup, ListGroupItem, Nav, NavItem, NavLink, TabContent, TabPane, } from 'reactstrap';
import ProductDescription from '../ProductDescription';
var LoadingIcon = function () { return React.createElement(FontAwesomeIcon, { icon: faCircleNotch, spin: true }); };
function FileListItem(_a) {
    var file = _a.file, disabled = _a.disabled, doDelete = _a.doDelete, loading = _a.loading;
    return (React.createElement("li", { className: 'merchi-embed-form_information-container-list-group-item no-z-index-hover' },
        React.createElement("div", { className: 'd-flex align-items-center' },
            React.createElement("span", null, file.name),
            !disabled && (React.createElement("div", { className: 'ml-auto' },
                React.createElement(Button, { color: 'secondary', download: true, href: file.downloadUrl, size: 'sm' },
                    React.createElement(FontAwesomeIcon, { icon: faDownload })),
                doDelete && (React.createElement(Button, { className: 'ml-2', color: 'danger', onClick: doDelete, disabled: Boolean(loading), size: 'sm' }, loading ? (React.createElement(LoadingIcon, null)) : (React.createElement(FontAwesomeIcon, { icon: faTrashAlt })))))))));
}
function ProcedureIcon(_a) {
    var icon = _a.icon;
    return (React.createElement("span", { className: 'm-r-10 pull-left' },
        React.createElement(FontAwesomeIcon, { icon: icon, size: '2x' })));
}
function ListGroupItemInfo(_a) {
    var children = _a.children, icon = _a.icon;
    return (React.createElement(ListGroupItem, { className: 'merchi-embed-form_information-container-list-group-item no-z-index-hover' },
        React.createElement("div", { className: 'd-flex align-items-center' },
            React.createElement("span", { className: 'mr-3' },
                React.createElement(ProcedureIcon, { icon: icon })),
            React.createElement("div", null, children))));
}
function ProductNormalDelivery(_a) {
    var product = _a.product;
    return product.deliveryDays ? (React.createElement(ListGroupItemInfo, { icon: faStopwatch },
        "Average turnaround time for this product is approximately $",
        product.deliveryDays,
        " days")) : (React.createElement(React.Fragment, null));
}
function ProductMOQ(_a) {
    var product = _a.product;
    var moq = product.minimum;
    return moq > 1 ? (React.createElement(ListGroupItemInfo, { icon: faExclamationCircle },
        "This product has a ",
        React.createElement("strong", null,
            "minimum order quantity of ",
            moq,
            " units"))) : (React.createElement(React.Fragment, null));
}
function ProductNeedsDrafting(_a) {
    var product = _a.product;
    if (product.needsDrafting) {
        return (React.createElement("li", { className: 'list-group-item no-z-index-hover' },
            React.createElement("div", { className: 'd-flex align-items-center' },
                React.createElement("span", { className: 'mr-3' },
                    React.createElement(ProcedureIcon, { icon: faDraftingCompass })),
                "This product requires that a draft must be approved before production commences.")));
    }
    return product.needsDrafting ? (React.createElement(ListGroupItemInfo, { icon: faDraftingCompass }, "This product requires that a draft must be approved before production commences.")) : (React.createElement(React.Fragment, null));
}
function ProductNeedsProduction(_a) {
    var product = _a.product;
    return product.needsProduction ? (React.createElement(ListGroupItemInfo, { icon: faHammer }, "This product has a production phase which may delay delivery.")) : (React.createElement(React.Fragment, null));
}
function ProductNeedsShipping(_a) {
    var product = _a.product;
    return product.needsShipping ? (React.createElement(ListGroupItemInfo, { icon: faTruck }, "This product is shipped directly to an address provided by you.")) : (React.createElement(React.Fragment, null));
}
function ProductInventoryCanBeChained(_a) {
    var product = _a.product;
    return product.allowChainedInventoryCreation ? (React.createElement(ListGroupItemInfo, { icon: faLink }, "Chained management services are available for this product, which include inventory managment and dropshipment.")) : (React.createElement(React.Fragment, null));
}
function ProductAllowGroupBuy(_a) {
    var product = _a.product;
    return product.allowGroupBuy ? (React.createElement(ListGroupItemInfo, { icon: faUsers },
        React.createElement("strong", { style: { marginRight: '0.2rem' } }, "Audience fund/crowdsource"),
        ' ',
        "campaigns can be created with this product.")) : (React.createElement(React.Fragment, null));
}
function ProductInformationSections(props) {
    var product = props.product;
    return (React.createElement(ListGroup, null,
        React.createElement(ProductAllowGroupBuy, { product: product }),
        React.createElement(ProductInventoryCanBeChained, { product: product }),
        React.createElement(ProductNormalDelivery, { product: product }),
        React.createElement(ProductMOQ, { product: product }),
        React.createElement(ProductNeedsDrafting, { product: product }),
        React.createElement(ProductNeedsProduction, { product: product }),
        React.createElement(ProductNeedsShipping, { product: product })));
}
function ProductPublicFiles(props) {
    var publicFiles = props.publicFiles;
    return (React.createElement(ListGroup, null, publicFiles.map(function (f, i) { return (React.createElement(FileListItem, { key: "".concat(i, "-publicfile"), file: f })); })));
}
function Item(_a) {
    var activeTab = _a.activeTab, name = _a.name, toggle = _a.toggle, value = _a.value;
    var isActive = activeTab === value;
    var className = isActive ? 'cursor-pointer text-white' : 'cursor-pointer';
    return (React.createElement(NavItem, { onClick: function () { return toggle(value); }, className: 'mb-0' },
        React.createElement(NavLink, { active: isActive, className: className }, name)));
}
function ProductInformation(_a) {
    var product = _a.product;
    var _b = product, description = _b.description, _c = _b.publicFiles, publicFiles = _c === void 0 ? [] : _c;
    var hasProductDesciption = !!description;
    var _d = __read(React.useState(hasProductDesciption ? '2' : '1'), 2), activeTab = _d[0], setActiveTab = _d[1];
    var hasPublicFiles = publicFiles.length > 0;
    var toggle = function (tab) {
        if (activeTab !== tab)
            setActiveTab(tab);
    };
    return (React.createElement("div", { className: 'merchi-embed-form_information-container' },
        React.createElement(Nav, { justified: true, pills: true },
            hasProductDesciption && (React.createElement(Item, { activeTab: activeTab, name: 'Description', toggle: toggle, value: '2' })),
            React.createElement(Item, { activeTab: activeTab, name: 'Information', toggle: toggle, value: '1' }),
            hasPublicFiles && (React.createElement(Item, { activeTab: activeTab, name: 'Files', toggle: toggle, value: '3' }))),
        React.createElement(TabContent, { className: 'py-3', activeTab: activeTab },
            hasProductDesciption && (React.createElement(TabPane, { tabId: '2' },
                React.createElement(ProductDescription, { description: description }))),
            React.createElement(TabPane, { tabId: '1' },
                React.createElement(ProductInformationSections, { product: product })),
            hasPublicFiles && (React.createElement(TabPane, { tabId: '3' },
                React.createElement(ProductPublicFiles, { publicFiles: publicFiles }))))));
}
export default ProductInformation;
