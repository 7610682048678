var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import sliceStripeAlerts from '../slices/sliceStripeAlerts';
import { useSelector, useDispatch } from 'react-redux';
var iconsMap = {
    danger: 'fa fa-exclamation-circle',
    info: 'fas fa-info-circle',
    success: 'fas fa-thumbs-up',
    warning: 'fa fa-exclamation-triangle',
};
function AlertSmall(_a) {
    var _b = _a.alertType, alertType = _b === void 0 ? 'info' : _b, close = _a.close, message = _a.message, title = _a.title, show = _a.show, _c = _a.position, position = _c === void 0 ? 'fixed' : _c;
    var icon = iconsMap[alertType];
    var msg = typeof message === 'string' ?
        message : message.errorMessage;
    return (React.createElement("div", { className: "alert alert-dismissible alert-notify alert-".concat(alertType), style: { display: 'inline-block',
            left: '0px',
            margin: '10px auto',
            position: position,
            right: '0px',
            top: '15px',
            zIndex: 100000
        } },
        React.createElement("span", { className: "alert-icon ".concat(icon), "data-notify": 'icon' }),
        React.createElement("div", { className: 'alert-text' },
            React.createElement("span", { className: 'alert-title', "data-notify": 'title' }, title),
            ' ',
            React.createElement("span", { "data-notify": 'message' }, msg)),
        close && (React.createElement("button", { type: 'button', className: 'close', "data-notify": 'dismiss', "aria-label": 'Close', onClick: function (e) {
                e.preventDefault();
                close();
            }, style: {
                position: 'absolute',
                right: '10px',
                top: '5px',
                zIndex: 999999999999
            } },
            React.createElement("span", { "aria-hidden": 'true' }, "\u00D7")))));
}
function StripeAlerts() {
    var alerts = useSelector(function (s) { return s.stateStripeAlerts; }).alerts;
    var dispatch = useDispatch();
    function close(index) {
        dispatch(sliceStripeAlerts.actions.alertRemove(index));
    }
    return (React.createElement("div", { className: 'd-flex justify-content-center flex-column alerts-container-sm' }, alerts.map(function (alert, index) {
        return React.createElement(AlertSmall, __assign({ key: "stripe-alert-index-key-".concat(index), position: 'relative', close: function () { return close(index); } }, alert));
    })));
}
export default StripeAlerts;
