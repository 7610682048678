import * as React from 'react';
import { ContentState, convertFromRaw, EditorState } from 'draft-js';
import createImagePlugin from '@draft-js-plugins/image';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/image/lib/plugin.css';
import createVideoPlugin from '@draft-js-plugins/video';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import { defaultThemeVideo, editorStyle, StyleEditor } from './editor_utils';
var blockDndPlugin = createBlockDndPlugin();
var decorator = composeDecorators(blockDndPlugin.decorator);
var imagePlugin = createImagePlugin();
var videoPlugin = createVideoPlugin({
    decorator: decorator,
    theme: defaultThemeVideo,
});
var plugins = [blockDndPlugin, imagePlugin, videoPlugin];
function ProductDescription(props) {
    var description = props.description;
    var message;
    var content;
    try {
        content = convertFromRaw(JSON.parse(description));
    }
    catch (_a) {
        if (!description || description === '') {
            message = '';
        }
        else {
            message = description;
        }
        content = ContentState.createFromText(message);
    }
    var initialEditorState = EditorState.createWithContent(content);
    var editorRef = React.useRef(null);
    return (React.createElement("div", { style: editorStyle },
        React.createElement(StyleEditor, null),
        React.createElement(Editor, { onChange: function () { return null; }, ref: editorRef, editorState: initialEditorState, readOnly: true, plugins: plugins })));
}
export default ProductDescription;
