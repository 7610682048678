var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { useDispatch } from 'react-redux';
import StripeAlerts from '../alerts/StripeAlerts';
import FormStripeCardFields from './FormStripeCardFields';
import stripeInitPromise from '../utilities/stripeInitPromise';
import { actionCheckIfWeChatPaymentIsAccepted, actionStripeCardFormSubmit, actionStripePaymentButtonSubmit, actionStripeWeChatPaymentButtonSubmit, } from '../store';
import { Badge } from 'reactstrap';
export function companyStripePubKeyOrTestPubKey(company) {
    return company.isTesting ? company.stripePublishableTestKey :
        company.stripePublishableKey;
}
var badgeTestMode = React.createElement(Badge, { color: 'warning' }, "Test mode");
function FormStripeCard(props) {
    var dispatch = useDispatch();
    var btnColor = props.btnColor, btnText = props.btnText, callbackStripePaymentSuccess = props.callbackStripePaymentSuccess, _a = props.hideLabels, hideLabels = _a === void 0 ? true : _a, invoice = props.invoice, isCart = props.isCart, PaymentButton = props.PaymentButton, showCardIcons = props.showCardIcons;
    var company = invoice.domain.company;
    var hasCompanyPubKey = Boolean(company.isStripeValid && companyStripePubKeyOrTestPubKey(company));
    var companyPubKey = hasCompanyPubKey ? companyStripePubKeyOrTestPubKey(company) : '';
    var canUseConnect = !!company.stripeAccountId;
    function doStripePayment(r) {
        actionStripeCardFormSubmit(dispatch, __assign({ callbackPaymentSuccess: callbackStripePaymentSuccess, isCart: isCart, invoice: invoice }, r));
    }
    function doStripePaymentRequestForButton(stripe, event) {
        actionStripePaymentButtonSubmit(dispatch, {
            callbackPaymentSuccess: callbackStripePaymentSuccess,
            isCart: isCart,
            invoice: invoice,
            stripe: stripe
        }, event);
    }
    function doStripePaymentRequestForWeChat(stripe) {
        actionStripeWeChatPaymentButtonSubmit(dispatch, {
            callbackPaymentSuccess: callbackStripePaymentSuccess,
            invoice: invoice,
            isCart: isCart,
            stripe: stripe,
        });
    }
    function doCheckIfWeChatPaymentIsAccepted(stripe) {
        actionCheckIfWeChatPaymentIsAccepted(dispatch, stripe, invoice);
    }
    var _b = __read(React.useState(companyPubKey), 2), stripePublicKey = _b[0], setStripePublicKey = _b[1];
    React.useEffect(function () {
        if (!stripePublicKey && canUseConnect)
            stripeInitPromise().then(setStripePublicKey);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(StripeAlerts, null),
        company.isTesting && badgeTestMode,
        React.createElement(FormStripeCardFields, { btnColor: btnColor, btnText: btnText, doStripePayment: doStripePayment, doCheckIfWeChatPaymentIsAccepted: doCheckIfWeChatPaymentIsAccepted, doStripePaymentRequestForWeChat: doStripePaymentRequestForWeChat, doStripePaymentRequestForButton: doStripePaymentRequestForButton, hideLabels: hideLabels, invoice: invoice, isCart: Boolean(isCart), PaymentButton: PaymentButton, showCardIcons: showCardIcons, stripePubKey: stripePublicKey })));
}
export default FormStripeCard;
