import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
function ButtonWeChatPayment(_a) {
    var doPaymentRequest = _a.doPaymentRequest, loading = _a.loading;
    return (React.createElement("div", { className: 'text-center pb-4' },
        React.createElement(Button, { block: true, color: 'primary', disabled: loading, onClick: doPaymentRequest, style: {
                backgroundColor: '#7BB32E',
                borderColor: '#7BB32E',
            } },
            loading ?
                React.createElement(FontAwesomeIcon, { icon: faCircleNotch, spin: true })
                :
                    React.createElement("i", { className: 'fab fa-weixin' }),
            " Pay with WeChat")));
}
export default ButtonWeChatPayment;
